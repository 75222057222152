<template>
    <v-app-bar flat color="black">
        <div class="ml-5 mr-5">
            <router-link to="/" custom v-slot="{ navigate }">
                <button @click="navigate" role="link">
                    <v-row no-gutters class="align-center pt-2">
                        <v-img :src="require('@/assets/logo-landscape.png')" :width="140" :height="70"></v-img>
                    </v-row>
                </button>
            </router-link>
        </div>
        <v-spacer></v-spacer>
        <v-btn variant="tonal" rounded="xl" color="white" class="d-none d-md-flex mr-1" to="/achieve-bench-maximization">
            {{ $t('findWork') }}
        </v-btn>
        <v-btn variant="tonal" rounded="xl" color="white" class="d-none d-md-flex mr-1" to="/empower-elastic-workforce">
            {{ $t('findTalent') }}
        </v-btn>
        <v-btn variant="tonal" rounded="xl" color="white" class="d-none d-md-flex mr-1" to="/story">
            {{ $t('story') }}
        </v-btn>
        <v-btn variant="tonal" rounded="xl" color="white" class="d-none d-md-flex mr-1" to="/articles">
            {{ $t('articles') }}
        </v-btn>
        <v-btn variant="tonal" rounded="xl" color="white" class="d-none d-md-flex mr-1" to="/contact">
            {{ $t('contact') }}
        </v-btn>
        <v-btn variant="tonal" rounded="xl" color="white" class="d-none d-md-flex mr-1" to="/events">
            {{ $t('events') }}
        </v-btn>
        <!--
        <v-btn variant="tonal" rounded="xl" to="/offering" class="mr-2">
            {{ $t('offerings') }}
        </v-btn>
        <v-btn variant="tonal" rounded="xl" to="/results" class="mr-2">
            {{ $t('results') }}
        </v-btn>
        <v-btn variant="tonal" rounded="xl" color="white" to="/story" class="mr-2">
            {{ $t('story') }}
        </v-btn>
        <v-btn variant="text" color="warning" class="mr-3 d-none d-md-flex" @click="demoRequest()">
            {{ $t('requestDemo') }}
        </v-btn>
        <v-btn variant="outlined" rounded="xl" color="warning" class="mr-5 pl-10 pr-10 d-none d-md-flex" @click="signUp()">
            {{ $t('signUp') }}
        </v-btn>-->
        <v-spacer></v-spacer>
        <v-btn variant="flat" rounded="xl" color="#EE8254" class="mr-5 pl-10 pr-10 d-none d-md-flex" @click="signIn()">
            {{ $t('signIn') }}
        </v-btn>
        <v-menu width="200" theme="dark" :right="true">
      <template v-slot:activator="{ props }">
        <v-btn class="d-flex d-md-none" color="#EE8254" v-bind="props" icon="mdi-menu"></v-btn>
      </template>
      <v-list>
        <!--
        <v-list-item @click="demoRequest()">
          <v-list-item-title>{{ $t('requestDemo') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="signUp()">
          <v-list-item-title>{{ $t('signUp') }}</v-list-item-title>
        </v-list-item>-->
        <v-list-item to="/achieve-bench-maximization">
          <v-list-item-title>{{ $t('findWork') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/empower-elastic-workforce">
          <v-list-item-title>{{ $t('findTalent') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/story">
          <v-list-item-title>{{ $t('story') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/articles">
          <v-list-item-title>{{ $t('articles') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/contact">
          <v-list-item-title>{{ $t('contact') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/events">
          <v-list-item-title>{{ $t('events') }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="signIn()">
          <v-list-item-title>{{ $t('signIn') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar>
</template>

<script>
export default {
    name: "AppBar",
    computed: {
        baseUrl() {
            return process.env.VUE_APP_CONSOLE_BASE_URL;
        }
    },
    methods: {
        contactUs() {
            this.$router.push("contact");
        },
        demoRequest() {
            this.$router.push("demo");
        },
        signUp() {
            window.open(this.baseUrl + "signUp", '_self');
        },
        signIn() {
            window.open(this.baseUrl + "signin", '_self');
        }
        
    }
};
</script>

<style scoped>


</style>